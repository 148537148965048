
<template>
    <p v-if="store.isDevelopment" style="position: absolute;">
        <span class="dev-span">
            <p class="dev-span-p">DEVELOPMENT</p>
        </span>
    </p>
    <div class="logo" @click="logoClick">
         <svg class="svg-container" width="877" height="230" viewBox="0 0 877 230" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M23.5083 105.893V165.18H50.5715V105.893H74.0798V84.9075H0V105.893H23.5083Z" fill="#CBCBCB" />
            <path
                d="M124.139 101.306C115.194 101.306 108.428 103.943 103.841 109.219V102.453H79.1864V165.18H105.103V137.314C105.103 128.369 109.46 123.782 118.405 123.782C120.125 123.782 122.075 124.012 124.139 124.241V101.306Z"
                fill="#CDBCC4" />
            <path
                d="M199.948 102.453H174.031V131.924C174.031 136.282 173.114 139.493 171.508 141.557C169.788 143.621 167.724 144.538 165.201 144.538C162.449 144.538 160.5 143.621 159.124 141.786C157.748 139.951 157.059 136.97 157.059 132.612V102.453H131.143V137.084C131.143 146.947 133.551 154.286 138.368 159.102C143.184 163.918 149.491 166.327 157.518 166.327C160.958 166.327 164.169 165.753 167.265 164.606C170.247 163.46 172.999 161.625 175.293 159.217V165.18H199.948V102.453Z"
                fill="#D0ACBC" />
            <path
                d="M220.046 164.606C225.207 165.753 230.482 166.326 235.871 166.326C242.752 166.326 248.715 165.409 253.646 163.46C258.462 161.625 262.132 158.987 264.655 155.776C267.063 152.566 268.324 149.011 268.324 145.112C268.324 140.066 266.948 136.052 264.196 133.3C261.444 130.548 258.348 128.713 254.793 127.681C251.238 126.764 246.88 125.961 241.834 125.273C238.509 124.929 236.101 124.585 234.61 124.126C233.005 123.668 232.316 122.979 232.316 121.947C232.316 119.998 235.069 118.966 240.802 118.966C247.339 118.966 253.417 120.571 258.921 123.553L266.031 106.466C262.82 104.861 258.921 103.599 254.334 102.682C249.747 101.765 245.16 101.306 240.458 101.306C233.578 101.306 227.729 102.338 222.913 104.173C218.097 106.122 214.427 108.645 212.019 111.856C209.496 115.067 208.349 118.737 208.349 122.75C208.349 127.91 209.611 131.809 212.363 134.562C215.115 137.314 218.211 139.263 221.881 140.181C225.436 141.098 229.794 141.901 234.954 142.474C238.28 142.933 240.688 143.277 242.178 143.621C243.669 144.08 244.472 144.768 244.472 145.685C244.472 146.717 243.784 147.52 242.637 147.979C241.49 148.437 239.426 148.667 236.445 148.667C232.66 148.667 228.762 148.208 224.863 147.176C220.849 146.258 217.409 144.882 214.542 143.048L207.432 160.134C210.643 161.969 214.771 163.46 220.046 164.606Z"
                fill="#D29DB5" />
            <path
                d="M322.273 163.001L316.081 144.997C314.361 146.144 312.411 146.717 310.232 146.717C308.627 146.717 307.251 146.258 306.334 145.112C305.301 144.08 304.843 142.589 304.843 140.639V124.814H317.686V105.893H304.843V88.3478H278.926V105.893H270.326V124.814H278.926V140.869C278.926 149.355 281.22 155.776 286.036 160.019C290.738 164.262 297.504 166.327 306.334 166.327C312.985 166.327 318.374 165.294 322.273 163.001Z"
                fill="#D58EAE" />
            <path d="M511.807 105.32H549.994V84.9075H484.744V165.18H511.807V139.493H545.292V119.081H511.807V105.32Z"
                fill="#DA6F9F" />
            <path
                d="M610.056 108.76C604.208 103.829 595.837 101.306 584.828 101.306C579.782 101.306 574.622 101.994 569.576 103.255C564.53 104.517 560.173 106.237 556.618 108.416L564.874 125.502C566.939 124.012 569.347 122.865 572.328 121.947C575.195 121.03 578.062 120.571 580.929 120.571C587.924 120.571 591.823 123.094 592.74 127.91H582.305C572.672 127.91 565.448 129.516 560.746 132.727C555.93 135.938 553.636 140.639 553.636 146.832C553.636 150.501 554.439 153.827 556.274 156.808C558.109 159.79 560.746 162.084 564.186 163.804C567.627 165.524 571.87 166.326 576.915 166.326C585.745 166.326 591.708 163.23 594.69 156.923V165.18H618.772V131.465C618.772 121.259 615.79 113.691 610.056 108.76ZM589.644 149.24C588.153 150.272 586.548 150.731 584.713 150.731C582.764 150.731 581.273 150.272 580.126 149.355C578.979 148.437 578.521 147.176 578.521 145.685C578.521 142.245 581.158 140.41 586.433 140.41H592.855V144.768C592.167 146.832 591.02 148.323 589.644 149.24Z"
                fill="#DC6097" />
            <path
                d="M645.656 162.198C651.275 164.95 657.697 166.326 664.806 166.326C672.375 166.326 678.911 164.721 684.416 161.395C689.805 158.185 693.59 153.598 695.654 147.749L675.586 137.887C673.063 143.392 669.508 146.144 664.692 146.144C661.595 146.144 659.073 145.112 657.008 142.933C654.944 140.869 654.027 137.772 654.027 133.759C654.027 129.86 654.944 126.878 657.008 124.7C659.073 122.635 661.595 121.489 664.692 121.489C669.508 121.489 673.063 124.241 675.586 129.745L695.654 119.883C693.59 114.15 689.805 109.563 684.416 106.237C678.911 103.026 672.375 101.306 664.806 101.306C657.697 101.306 651.275 102.682 645.656 105.434C640.037 108.186 635.679 112.085 632.468 117.016C629.257 121.947 627.766 127.566 627.766 133.759C627.766 140.066 629.257 145.685 632.468 150.616C635.679 155.662 640.037 159.446 645.656 162.198Z"
                fill="#DF5190" />
            <path
                d="M748.977 163.001L742.785 144.997C741.065 146.144 739.115 146.717 736.937 146.717C735.331 146.717 733.955 146.258 733.038 145.112C732.006 144.08 731.547 142.589 731.547 140.639V124.814H744.39V105.893H731.547V88.3478H705.63V105.893H697.03V124.814H705.63V140.869C705.63 149.355 707.924 155.776 712.74 160.019C717.442 164.262 724.208 166.327 733.038 166.327C739.689 166.327 745.078 165.294 748.977 163.001Z"
                fill="#E14289" />
            <path
                d="M768.164 162.198C773.668 164.95 779.976 166.326 786.971 166.326C793.966 166.326 800.273 164.95 805.777 162.198C811.282 159.446 815.639 155.547 818.735 150.616C821.832 145.685 823.437 140.066 823.437 133.759C823.437 127.566 821.832 121.947 818.735 117.016C815.639 112.085 811.282 108.186 805.777 105.434C800.273 102.682 793.966 101.306 786.971 101.306C779.976 101.306 773.668 102.682 768.164 105.434C762.66 108.186 758.302 112.085 755.206 117.016C751.995 121.947 750.504 127.566 750.504 133.759C750.504 140.066 751.995 145.685 755.206 150.616C758.302 155.547 762.66 159.446 768.164 162.198ZM794.31 142.933C792.36 145.112 789.952 146.144 786.971 146.144C783.989 146.144 781.466 145.112 779.631 142.933C777.682 140.754 776.765 137.658 776.765 133.759C776.765 129.86 777.682 126.878 779.631 124.7C781.466 122.635 783.989 121.489 786.971 121.489C789.952 121.489 792.36 122.635 794.31 124.7C796.145 126.878 797.177 129.86 797.177 133.759C797.177 137.658 796.145 140.754 794.31 142.933Z"
                fill="#E43281" />
            <path
                d="M876.501 101.306C867.556 101.306 860.791 103.943 856.204 109.219V102.453H831.548V165.18H857.465V137.314C857.465 128.369 861.823 123.782 870.767 123.782C872.487 123.782 874.437 124.012 876.501 124.241V101.306Z"
                fill="#E6237A" />
            <rect x="282.608" width="229.954" height="229.954" :fill="`url(#${usedIdPattern})`" />
            
            <defs>
                <pattern :id="usedIdPattern" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use :xlink:href="`#${usedIdImage}`" transform="scale(0.00195312)" />
                </pattern>
                <image crossorigin="anonymous" :id="usedIdImage" width="512" height="512" :xlink:href="img" />
            </defs> 
        </svg>
    </div>
</template>
<script lang="ts" setup>
import { useMainStore } from "../stores/MainStore";
import img from "./../assets/middle-logo.png";
import { ref } from 'vue';

// logic for evade chrome bug (when double instance of this svg overloaped on z-index - image not displayed) and immortal views in ion-router
let s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
}
const usedIdPattern = `svg_pattern_${s4()}`;
const usedIdImage = `svg_image_${s4()}`;
// end

const counterDevMode = ref(0);
const store = useMainStore();


function logoClick() {
    
    if (counterDevMode.value >= 5) {
        counterDevMode.value = 0;
        if (store.isDevelopment)
        {
            console.log(`dev mode activated`);
            store.useProdService();
        }
        else
            store.useDevService();
    }
    else
    {
        if (!store.isDevelopment)
            console.log(`dev activator clicked, remain: ${5 - counterDevMode.value}`);
        counterDevMode.value++;
    }
}
</script>

<style scoped>
ion-item {
    filter: drop-shadow(0 0 55px #646cffaa) drop-shadow(0 0 55px #646cffaa);
}

.logosvg {
    display: flex;
    margin: auto;
    justify-content: center;
    text-align: center;
    left: 50%;
    position: relative;
    transform: translateX(-50%) scale(0.5);
    top: -15px;
}

.svg-container {
    filter: drop-shadow(0 0 45px #646cffaa) drop-shadow(0 0 55px #646cffaa);
    width: 100%;
    height: 100px;
}

.dev-span {
    transform: rotate(35deg) translate(270px, -150px);
    position: absolute;
    transform-origin: top;
    z-index: 1;
}

.dev-span-p {
    color: #f7f700;
    text-shadow: 2px 2px 1px #3f3f00;
    text-decoration: none;

    animation: animationFrames linear .7s;
    animation-iteration-count: infinite;
}

@keyframes animationFrames {
    0% {
        left: 0px;
        top: 0px;
        opacity: 1;
        transform: rotate(-15deg) scaleX(1) scaleY(1) skewX(0deg) skewY(0deg);
    }

    50% {
        transform: rotate(-15deg) scaleX(1.2) scaleY(1.2) skewX(0deg) skewY(0deg);
    }

    100% {
        left: 0px;
        top: 0px;
        opacity: 1;
        transform: rotate(-15deg) scaleX(1) scaleY(1) skewX(0deg) skewY(0deg);
    }
}
.logo {
    cursor: pointer;
    user-select: none;
}
.logo:active {
    transform: scale(.96);
}
</style>

